import { Launch } from '@lightningjs/sdk';
import App from '@dw-cds/smart-tv-app';
import examples from './examples';
import customPolyfills from './polyfills';
import { Platforms } from '@dw-cds/smart-tv-core';

const EXAMPLE = {
    mood: false,
    component: 'networking',
};

export default function() {
    customPolyfills();
    //arguments[0]: appSettings
    //arguments[1]: platformSettings
    arguments[0].keys = Platforms.platform.getKeyMappings();
    arguments[0].stage.memoryPressure = 4000000;
    arguments[1].fontLoader = (fonts, store) =>
        new Promise((resolve, reject) => {
            const prepareUrl = url => {
                return 'url(' + url + ')';
            };

            const loads = fonts.map(({ family, url, urls, descriptors }) => {
                const src = urls ? urls.map(prepareUrl) : prepareUrl(url);
                const fontFace = new FontFace(family, src, descriptors || {});

                store.push(fontFace);
                document.fonts.add(fontFace);

                return fontFace.load();
            });
            Promise.all(loads)
                .then(resolve)
                .catch(reject);
        });

    return Launch(EXAMPLE.mood ? examples[EXAMPLE.component] : App, ...arguments);
}
