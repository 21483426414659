export default function customPolyfills() {
    if (!Object.entries) {
        Object.entries = function (obj) {
            var ownProps = Object.keys(obj),
                i = ownProps.length,
                resArray = new Array(i);
            while (i--)
                resArray[i] = [ownProps[i], obj[ownProps[i]]];

            return resArray;
        };
    }

    if (!Object.fromEntries) {
        Object.fromEntries = function(iterable) {
            return [...iterable].reduce((obj, [key, val]) => {
                obj[key] = val
                return obj
            }, {});
        }
    }

    if (Promise && !Promise.allSettled) {
        Promise.allSettled = function (promises) {
          return Promise.all(promises.map(function (promise) {
            return promise.then(function (value) {
              return { state: 'fulfilled', value: value };
            }).catch(function (reason) {
              return { state: 'rejected', reason: reason };
            });
          }));
        };
      }
}